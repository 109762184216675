<template>
  <div>
    <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
      <a-divider orientation="left">
        <b>{{ formTitle }}</b>
      </a-divider>
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="位置" prop="location">
          <a-select style="width: 250px" v-model="form.location" placeholder="请选择位置">
            <a-select-option v-for="(item, index) in this.customDict.LocationEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="类型" prop="type">
          <a-select style="width: 250px" v-model="form.type" placeholder="请选择类型">
            <a-select-option v-for="(item, index) in this.customDict.BannerTypeEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="跳转类型" prop="jumpType">
          <a-select style="width: 250px" v-model="form.jumpType" placeholder="请选择跳转类型" @change="changeType">
            <a-select-option v-for="(item, index) in this.customDict.JumpTypeEnum" :value="item.type" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          :label="typeLabels[form.jumpType]"
          prop="jumpInfo"
          v-if="form.jumpType && form.jumpType !== '1' && form.jumpType !== '8'"
        >
          <a-button type="dashed" @click="jumpFunction()" danger v-if="form.jumpType !== '9'">
            <span style="color: dodgerblue"> {{ form.remark }}</span>
          </a-button>
          <a-input v-model="form.jumpInfo" placeholder="请输入外联链接" v-if="form.jumpType === '9'"/>
        </a-form-model-item>

        <a-form-model-item label="广告名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入广告名称"/>
        </a-form-model-item>

        <a-form-model-item label="高清图" prop="img">
          <a-upload
            name="img"
            listType="picture-card"
            class="avatar-uploader"
            :multiple="true"
            :show-upload-list="false"
            :preview="handlePreview"
            :before-upload="beforeOssUpload"
            :customRequest="imgAvatarUpload">
            <img
              v-if="form.img"
              :src="form.img"
              alt="img"
              style="height: 102px; width: 102px;"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item label="背景色" prop="backgroundColor">
          <a-input v-model="form.backgroundColor" placeholder="请输入背景色"/>
        </a-form-model-item>

        <a-form-model-item label="小图" prop="smallImg">
          <a-upload
            name="smallImg"
            listType="picture-card"
            class="avatar-uploader"
            :multiple="true"
            :show-upload-list="false"
            :preview="handlePreview"
            :before-upload="beforeOssUpload"
            :customRequest="smallImgAvatarUpload">
            <img
              v-if="form.smallImg"
              :src="form.smallImg"
              alt="smallImg"
              style="height: 102px; width: 102px;"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="音频" prop="audio">
          <OssMultiUploadSingle v-model="form.audio" type="video" :maxSize="200"></OssMultiUploadSingle>
        </a-form-model-item>

        <a-form-model-item label="显示排序" prop="sort">
          <a-input-number v-model="form.sort" :min="0" :max="128" :precision="0" style="width: 100%"/>
        </a-form-model-item>
        <!--        <a-form-model-item label="备注" prop="remark">
                  <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear/>
                </a-form-model-item>-->
        <a-form-model-item label="开始时间" prop="startTime">
          <a-date-picker
            v-model="form.startTime"
            @change="onDateChange"
            :showTime="true"
            format="YYYY-MM-DD HH:mm:ss"
            style="width: 100%"
            allow-clear/>
        </a-form-model-item>
        <a-form-model-item label="结束时间" prop="endTime">
          <a-date-picker
            v-model="form.endTime"
            @change="onDateChange1"
            :showTime="true"
            format="YYYY-MM-DD HH:mm:ss"
            style="width: 100%"
            allow-clear/>
        </a-form-model-item>

        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              保存
            </a-button>
            <a-button type="dashed" @click="cancel">
              取消
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </a-drawer>
    <index-script-modal ref="IndexScriptModal" @selectAll="indexScriptAll"/>
    <index-expo-modal ref="IndexExpoModal" @selectAll="indexExpoAll"/>
    <shop-list-modal ref="ShopListModal" @selectAll="indexShopAll"/>
    <sheet-list-modal ref="SheetListModal" @selectAll="indexSheetAll"/>
    <activity-list-modal ref="ActivityListModal" @selectAll="indexActivityAll"/>
    <user-list-modal ref="UserListModal" @selectAll="indexUserAll"/>
  </div>
</template>

<script>
import {getBanner, addBanner, updateBanner} from '@/api/config/banner'
import {mapGetters} from 'vuex'
import {generateFilePath, uploadObject} from "@/api/tool/alioss";
import IndexScriptModal from "@/components/Script/IndexScriptModal";
import IndexExpoModal from "@/views/expo/expoScript/modules/indexExpoModal";
import ShopListModal from "@/components/Shop/ShopListModal.vue";
import SheetListModal from "@/views/script/sheet/SheetListModal";
import ActivityListModal from "@/views/activity/activity/ActivityListModal";
import UserListModal from "@/views/user/user/UserListModal";
import OssMultiUploadSingle from "@/views/script/script/modules/OssMultiUploadSingle";

export default {
  name: 'CreateForm',
  props: {},
  components: {
    IndexExpoModal,
    ShopListModal,
    SheetListModal,
    UserListModal,
    ActivityListModal,
    OssMultiUploadSingle,
    IndexScriptModal
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      formTitle: '',
      fileList: [],
      fileImgList: [],
      talkInfoVideo: [],
      selectLabel: '',
      typeLabels: ['请绑定剧本', '', '请绑定店铺', '请绑定剧本集', '请绑定活动', '请绑定展会', '请绑定用户', '请绑定商家', '', '请输入外联链接'],
      // 表单参数
      form: {
        id: null,
        location: undefined,
        type: undefined,
        name: null,
        img: null,
        backgroundColor: null,
        smallImg: null,
        audio: null,
        startTime: null,
        endTime: null,
        jumpType: undefined,
        jumpInfo: null,
        jumpInfoName: null,  //1
        sort: 0,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        location: [
          {required: true, message: '请选择banner位置', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择类型', trigger: 'change'}
        ],
        name: [
          {required: true, message: '广告名称不能为空', trigger: 'blur'}
        ],
        img: [
          {required: true, message: '高清图不能为空', trigger: 'blur'}
        ],
        isDeleted: [
          {required: true, message: '逻辑删除标记 1:删除,0:未删除不能为空', trigger: 'blur'}
        ],
        jumpType: [
          {required: true, message: '请选择跳转类型', trigger: 'blur'}
        ],
        jumpInfo: [
          {required: true, message: '跳转类型绑定不能为空', trigger: 'blur'}
        ],
        startTime: [
          {required: true, message: '开始时间不能为空', trigger: 'blur'}
        ],

        endTime: [
          {required: true, message: '结束时间不能为空', trigger: 'blur'}
        ],
        version: [
          {required: true, message: '乐观锁不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.talkInfoImage = []
      this.talkInfoVideo = []
      this.fileList = []
      this.fileImgList = []
      this.form = {
        id: null,
        location: undefined,
        type: undefined,
        name: null,
        img: null,
        backgroundColor: null,
        smallImg: null,
        audio: null,
        startTime: null,
        endTime: null,
        jumpType: undefined,
        jumpInfo: null,
        jumpInfoName: null,
        sort: 0,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    onDateChange(date, dateString) {
      console.log(date, dateString)
      this.form.startTime = dateString
    },
    onDateChange1(date, dateString) {
      console.log(date, dateString)
      this.form.endTime = dateString
    },

    changeType(value) {
      this.form.jumpInfo = null
      this.form.remark = this.typeLabels[this.form.jumpType]
    },
    jumpFunction () {
      if (this.form.jumpType === '0') {
        this.$refs.IndexScriptModal.open();
      } else if (this.form.jumpType === '2') {
        this.$refs.ShopListModal.open();
      } else if (this.form.jumpType === '3') {
        this.$refs.SheetListModal.open();
      } else if (this.form.jumpType === '4') {
        this.$refs.ActivityListModal.open();
      } else if (this.form.jumpType === '5') {
        this.$refs.IndexExpoModal.open();
      } else if (this.form.jumpType === '6') {
        this.$refs.UserListModal.open();
      } else if (this.form.jumpType === '7') {
        this.$refs.ShopListModal.open();
      } else if (this.form.jumpType === '8') {
        // 商务目前没有
      }
    },

    // 0.剧本
    indexScriptAll(row) {
      this.form.jumpInfo = row.id;
      console.log("获取到的ids", row.id);
      this.$set(this.form, 'jumpInfoName', row.name)
      this.$set(this.form, 'remark', row.name)
      console.log("获取到的scriptName", row.name, "123", this.form);
    },
    // 2.店铺信息
    indexShopAll(row) {
      this.form.jumpInfo = row.id;
      console.log("获取到的ids", row.id);
      this.$set(this.form, 'jumpInfoName', row.shopName)
      this.$set(this.form, 'remark', row.shopName)
      console.log("获取到的店铺", row.shopName, "123", this.form);
    },
    // 3.剧本集
    indexSheetAll(row) {
      this.form.jumpInfo = row.id;
      console.log("获取到的ids", row.id);
      this.$set(this.form, 'jumpInfoName', row.name)
      this.$set(this.form, 'remark', row.name)
      console.log("获取到的剧本集", row.name, "123", this.form);
    },
    // 4.活动
    indexActivityAll(row) {
      this.form.jumpInfo = row.id;
      console.log("获取到的ids", row.id);
      this.$set(this.form, 'jumpInfoName', row.name)
      this.$set(this.form, 'remark', row.name)
      console.log("获取到的活动", row.name, "123", this.form);
    },
    // 5.展会
    indexExpoAll(row) {
      this.form.jumpInfo = row.id;
      console.log("获取到的ids", row.id);
      this.$set(this.form, 'jumpInfoName', row.name)
      this.$set(this.form, 'remark', row.name)
    },
    // 6.用户
    indexUserAll(row) {
      this.form.jumpInfo = row.id;
      console.log("获取到的ids", row.id);
      this.$set(this.form, 'jumpInfoName', row.nickName)
      this.$set(this.form, 'remark', row.nickName)
      console.log("获取到的用户", row.nickName, "123", this.form);
    },

    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBanner(id).then(response => {
        Object.assign(this.form, response.data)
        this.open = true
        this.formTitle = '修改'

        // 处理图片反显
        if (response.data.smallImg !== undefined && response.data.smallImg !== null && JSON.stringify(response.data.smallImg) !== '"{}"') {
          var images = this.form.smallImg.split(',')
          for (var i = 0; i < images.length; i++) {
            this.fileImgList.push({status: 'done', url: images[i], uid: this.getUidRandom(), name: 'smallImg.jpg'})
          }
        }
        if (response.data.audio !== undefined && response.data.audio !== null) {
          var videos = this.form.audio.split(',')
          for (var e = 0; e < videos.length; e++) {
            this.talkInfoVideo.push({status: 'done', url: videos[e], uid: this.getUidRandom(), name: 'audio.mp4'})
          }
        }

      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBanner(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBanner(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    smallImgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'sript'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'smallImg', res)
          _this.$message.success('上传成功')
        })
      })
    },
    // 上传用户头像覆盖默认的上传行为
    imgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'sript'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'img', res)
          _this.$message.success('上传成功')
        })
      })
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
  }
}
</script>
