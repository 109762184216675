<template>

    <a-modal v-model:visible="visible" title="活动列表" width="50%" v-highlight>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="活动名称" prop="activityName">
                <a-input v-model="queryParam.activityName" placeholder="请输入活动名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="状态" prop="activityStatus">
                <a-select placeholder="请选择状态" style="width: 100%" v-model="queryParam.activityStatus" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.ActivityStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['activity:activity:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['activity:activity:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['activity:activity:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['activity:activity:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="applyStartTime" slot-scope="text, record">
          {{ parseTime(record.applyStartTime) }}
        </span>
        <span slot="applyEndTime" slot-scope="text, record">
          {{ parseTime(record.applyEndTime) }}
        </span>
        <span slot="activityStartTime" slot-scope="text, record">
          {{ parseTime(record.activityStartTime) }}
        </span>
        <span slot="activityEndTime" slot-scope="text, record">
          {{ parseTime(record.activityEndTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="applyPostEndTime" slot-scope="text, record">
          {{ parseTime(record.applyPostEndTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
           <a @click="bind(record)">
            <a-icon type="delet" />绑定
          </a>
        </span>
        <span slot="activityStatus" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（状态）-->
           <custom-dict-tag :options="customDict.ActivityStatusEnum" :value="record.activityStatus"/>
        </span>
        <template slot="activityPics" slot-scope="text, record">
          <div>
            <img v-if="record.activityPics" :src="record.activityPics"  style="width:60px;height:62px;"/>
          </div>
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    </a-modal>
</template>

<script>
import { listActivity, delActivity } from '@/api/activity/activity'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
export default {
  name: 'Activity',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
        visible: false,

        // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        activityName: null,
        applyStartTime: null,
        applyEndTime: null,
        activityStartTime: null,
        activityEndTime: null,
        activityTags: null,
        activityStatus: null,
        activityDesc: null,
        activityApplyNum: null,
        applyJoinNum: null,
        activityCompleteNum: null,
        createBy: null,
        updateBy: null,
        sortNum: null,
        isTop: null,
        activityPics: null,
        activityYear: null,
        activityMonth: null,
        activityTaskGoal: null,
        activityTaskType: null,
        frieghtId: null,
        applyPostEndTime: null,
        acPrice: null,
        prizeType: null,
        prize: null,
        activityTaskNum: null,
        applyMaxNum: null,
        activityVirtualApplyNum: null,
        textShowIndex: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '名称',
          dataIndex: 'activityName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '活动封面',
          dataIndex: 'activityPics',
          scopedSlots: { customRender: 'activityPics' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '报名开始',
          dataIndex: 'applyStartTime',
          scopedSlots: { customRender: 'applyStartTime' },
          ellipsis: true,
          align: 'center'
        },
       /* {
          title: '报名结束',
          dataIndex: 'applyEndTime',
          scopedSlots: { customRender: 'applyEndTime' },
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '开始时间',
          dataIndex: 'activityStartTime',
          scopedSlots: { customRender: 'activityStartTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '结束时间',
          dataIndex: 'activityEndTime',
          scopedSlots: { customRender: 'activityEndTime' },
          ellipsis: true,
          align: 'center'
        },

        {
          title: '状态',
          dataIndex: 'activityStatus',
          ellipsis: true,
          scopedSlots: { customRender: 'activityStatus' },
          align: 'center'
        },

        {
          title: '活动价格',
          dataIndex: 'acPrice',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '目标次数',
          dataIndex: 'activityTaskNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '报名上限',
          dataIndex: 'applyMaxNum',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {    ...mapGetters(['customDict'])

  },
  watch: {
  },
  methods: {
    /** 查询活动列表 */
    getList () {
      this.loading = true
      listActivity(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        activityName: undefined,
        applyStartTime: undefined,
        applyEndTime: undefined,
        activityStartTime: undefined,
        activityEndTime: undefined,
        activityTags: undefined,
        activityStatus: undefined,
        activityDesc: undefined,
        activityApplyNum: undefined,
        applyJoinNum: undefined,
        activityCompleteNum: undefined,
        createBy: undefined,
        updateBy: undefined,
        sortNum: undefined,
        isTop: undefined,
        activityPics: undefined,
        activityYear: undefined,
        activityMonth: undefined,
        activityTaskGoal: undefined,
        activityTaskType: undefined,
        frieghtId: undefined,
        applyPostEndTime: undefined,
        acPrice: undefined,
        prizeType: undefined,
        prize: undefined,
        activityTaskNum: undefined,
        applyMaxNum: undefined,
        activityVirtualApplyNum: undefined,
        textShowIndex: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delActivity(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('activity/activity/export', {
            ...that.queryParam
          }, `activity_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    bind (row) {
        const ids = row.id || this.ids;
        const name = row.name
        this.visible = false;
        this.$emit('select', ids)
        this.$emit('selectname', name)
        this.$emit('selectAll', row)
    },
    open(){
        this.visible = true;
    }
  }
}
</script>
